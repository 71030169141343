
.section-prices{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
}

.section-prices-image-tile{
    background-image: url('../../../src/assets/images/showroom1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 50vw;
    display: flex;
}

.section-prices-tile{
    background-color: var(--primary-black);
    width: 50vw;
    min-height: 40vw;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
}

.section-title{
    width: 100%;
    /* background-color: darkcyan; */
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-size: 45px;
    color: var(--title-color);
    text-transform: uppercase;
    margin-bottom: 30px;
}

.section-prices-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: tomato; */
}

.section-prices-selection-container{
    width: 90%;
    padding: 0 5%;
    /* background-color: thistle; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 25px;
    margin-bottom: 20px;
}

.cat-button{
    color: var(--primary-white);
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    margin-right: 1.2vw;
    font-size: 24px;
    padding: 4px 12px;
    border-radius: 8px;
}

.cat-button:last-child{
    margin: 0;
}

.cat-button.active{
    background-color: rgba(217, 175, 106, 0.2);
}


.section-prices-wrapper{
    width: 86%;
    padding: 20px 7%;
    /* background-color: wheat; */
    display: table;
    color: var(--primary-white);
}

.price-row{
    display: table-row;
    font-size: 22px;
    line-height: 22px;
}

.info-row{
    display: table-row;
    font-size: 21px;
    line-height: 21px;
}

.description{
    display: table-cell;
    /* background-color: saddlebrown; */
    vertical-align: middle;
    border-bottom: 1px solid #3B3B3B;
    padding: 8px 5px;
}

.info{
    display: table-cell;
    /* background-color: saddlebrown; */
    vertical-align: middle;
    padding: 28px 5px 8px 5px;
    font-style: italic;
}


.price{
    display: table-cell;
    /* background-color: salmon; */
    font-style: italic;
    white-space: nowrap;
    vertical-align: middle;
    border-bottom: 1px solid #3B3B3B;
    padding: 8px 5px;
}

.price-row:last-child > .price, .price-row:last-child > .description{
    border: none;
}


/* smaller screens */
@media screen and (max-width: 1200px) {

    .section-title{
        font-size: 40.6px;
        margin-bottom: 18px;
    }

    .section-prices-selection-container{
        margin-bottom: 18px;
    }

    .cat-button{
        margin-right: 1vw;
        font-size: 22.8px;
        padding: 3.8px 10px;
        border-radius: 7px;
    }

    .price-row{
        font-size: 20px;
        line-height: 20px;
    }

}

/* tablets and smartphones */
/* portrait orientation */
@media screen and (max-width: 768px){
    
    .section-prices{
        width: 100vw;
        flex-direction: column-reverse;
    }
    
    .section-prices-image-tile{
        width: 100vw;
        height: 190px;
    }

    .section-prices-tile{
        width: 100vw;
        padding: 34px 0;
        min-height: 80vw;
    }

    .section-title{
        font-size: 8.4vw;
        margin-bottom: 14px;
    }
    
    .section-prices-selection-container{
        margin-bottom: 12px;
    }

    .cat-button{
        margin-right: 1vw;
        font-size: 5.2vw;
        padding: 3.8px 10px;
        border-radius: 6px;
    }

    .price-row{
        font-size: 4.5vw;
        line-height: 4.2vw;
    }
    
    .description{
        padding: 7.5px 5px;
    }

    .info-row{
        font-size: 4.4vw;
        line-height: 4.2vw;
    }
    
    .info{
        padding: 7.5px 5px;
    }
    
    .price{
        padding: 7.5px 5px;
    }

}

/* landscape orientation */
@media screen and (max-width: 768px) and (orientation: landscape){

    .section-prices-image-tile{
        height: 120px;
    }

    .section-prices-tile{
        min-height: 46vw;
    }

    .section-title{
        font-size: 5vw;
        margin-bottom: 12px;
    }

    .cat-button{
        margin-right: 1vw;
        font-size: 3vw;
        padding: 4px 10px;
    }

    .price-row{
        font-size: 2.5vw;
        line-height: 2.5vw;
    }
    
    .description{
        padding: 7.5px 5px;
    }

    .info-row{
        font-size: 2.5vw;
        line-height: 2.5vw;
    }
    
    .info{
        padding: 7.5px 5px;
    }
    
    .price{
        padding: 7.5px 5px;
    }

}



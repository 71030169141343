@font-face {
  font-family: 'Perpetua';
  src: url('../src/assets/fonts/Perpetua.eot');
  src: url('../src/assets/fonts/Perpetua.woff') format('woff'),
  url('../src/assets/fonts/Perpetua.woff2') format('opentype'),
  url('../src/assets/fonts/Perpetua.svg') format('svg');
}

:root{
  --app-height: 100%;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Perpetua';
  font-variant: normal;
  font-style: normal;
}

html{
  --primary-black: #2d2d2d;
  --secondary-black: #565656;
  --primary-white: #fff;
  --secondary-white: #e1e1e1;
  --title-color: #D9AF6A;
  overflow-y: scroll;
  overflow-x: hidden;
}

